import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import clsx from 'clsx';
import { Suspense, useId } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { primary, secondary } from '../../../utils/theme';
import { handleError } from '../../blocks/Error';
import Loader from '../../common/Loader';
import { createHeader } from '../useHeader';

const beforeCapture: ErrorBoundaryProps['beforeCapture'] = scope => {
    scope.setTag('boundary', 'DashboardLayout');
};

type NavigationItem = { name: string; href: string; current?: boolean };

type UserNavigationItem = { name: string; href: string };

type User = { name: string; email: string; imageUrl: string };

export type StackedLayoutProps = {
    navigation: Array<NavigationItem>;
    userNavigation: Array<UserNavigationItem>;
    user: User;
};

const StackedLayout = ({ navigation, userNavigation, user }: StackedLayoutProps) => {
    const headerId = useId();
    const header = createHeader(headerId, 'bg-gray-200 shadow-sm');

    const logoUrl = new URL('../../../public/icons/retain-fav-icon.png', import.meta.url).href;

    return (
        <div className="min-h-full">
            <Disclosure as="nav" className="border-b border-gray-200 bg-white">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 justify-between">
                                <div className="flex">
                                    <div className="flex flex-shrink-0 items-center">
                                        <img alt="Your Company" className="block h-8 w-auto lg:hidden" src={logoUrl} />
                                        <img alt="Your Company" className="hidden h-8 w-auto lg:block" src={logoUrl} />
                                    </div>
                                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                        {navigation.map(item => (
                                            <Link
                                                key={item.name}
                                                aria-current={item.current ? 'page' : undefined}
                                                className={clsx(
                                                    item.current
                                                        ? `${primary.borderColor} ${secondary.textColor}`
                                                        : // eslint-disable-next-line max-len
                                                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                    'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                                                )}
                                                to={item.href}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3" data-cy="userNav">
                                        <div>
                                            <MenuButton
                                                // eslint-disable-next-line max-len
                                                className={`relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 ${primary.focusRingColor} focus:ring-offset-2`}
                                                data-cy="userNavButton"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">Open user menu</span>
                                                <img alt="" className="h-8 w-8 rounded-full" src={user.imageUrl} />
                                            </MenuButton>
                                        </div>
                                        <Transition
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <MenuItems
                                                // eslint-disable-next-line max-len
                                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            >
                                                {userNavigation.map(item => (
                                                    <MenuItem key={item.name}>
                                                        {({ focus }) => (
                                                            <Link
                                                                className={clsx(
                                                                    focus && 'bg-gray-100',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                                to={item.href}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </MenuItems>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <DisclosureButton
                                        // eslint-disable-next-line max-len
                                        className={`relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 ${primary.focusRingColor} focus:ring-offset-2`}
                                    >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon aria-hidden="true" className="block h-6 w-6" />
                                        ) : (
                                            <Bars3Icon aria-hidden="true" className="block h-6 w-6" />
                                        )}
                                    </DisclosureButton>
                                </div>
                            </div>
                        </div>

                        <DisclosurePanel className="sm:hidden">
                            <div className="space-y-1 pb-3 pt-2">
                                {navigation.map(item => (
                                    <DisclosureButton
                                        key={item.name}
                                        aria-current={item.current ? 'page' : undefined}
                                        as={Link}
                                        className={clsx(
                                            item.current
                                                ? // eslint-disable-next-line max-len
                                                  `${primary.borderColor} ${primary.bgColorTransparent} ${primary.textColor}`
                                                : // eslint-disable-next-line max-len
                                                  `border-transparent text-gray-600 hover:border-gray-300 ${secondary.hoverBgColor} hover:text-gray-800`,
                                            'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                                        )}
                                        to={item.href}
                                    >
                                        {item.name}
                                    </DisclosureButton>
                                ))}
                            </div>
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                <div className="flex items-center px-4">
                                    <div className="flex-shrink-0">
                                        <img alt="" className="h-10 w-10 rounded-full" src={user.imageUrl} />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">{user.name}</div>
                                        <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                    </div>
                                    <button
                                        // eslint-disable-next-line max-len
                                        className={`relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 ${primary.focusRingColor} focus:ring-offset-2`}
                                        type="button"
                                    >
                                        <span className="absolute -inset-1.5" />
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon aria-hidden="true" className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className="mt-3 space-y-1">
                                    {userNavigation.map(item => (
                                        <DisclosureButton
                                            key={item.name}
                                            as={Link}
                                            // eslint-disable-next-line max-len
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                            to={item.href}
                                        >
                                            {item.name}
                                        </DisclosureButton>
                                    ))}
                                </div>
                            </div>
                        </DisclosurePanel>
                    </>
                )}
            </Disclosure>

            <div id={headerId} />

            <div className="py-10">
                <main>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <ErrorBoundary beforeCapture={beforeCapture} fallback={handleError}>
                            <Suspense fallback={<Loader />}>{header.render(<Outlet />)}</Suspense>
                        </ErrorBoundary>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default StackedLayout;
